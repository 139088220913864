<template>
  <transition
    name="fade"
    mode="out-in"
  >
    <v-snackbar
      v-if="message"
      v-model="message"
      :value="1"
      :timeout="-1"
    >
      <v-row
        no-gutters
      >
        <v-col
          cols="11"
          class="d-flex align-center"
        >
          <label
            :class="`font-weight-medium font-size-md primary--text ${getColor(message)}--text`"
          >
            {{ message.text !== null ? $t(message.text) : $t('messages.components.core.flash.unknownError') }}
          </label>
        </v-col>
        <v-col
          cols="1"
        >
          <v-icon
            v-if="isDismissible(message)"
            :color="getColor(message)"
            @click="next"
          >
            mdi-close
          </v-icon>
        </v-col>
      </v-row>
      <v-spacer />
    </v-snackbar>
  </transition>
</template>

<script>
  import { mapState, mapMutations } from 'vuex';

  export default {
    name: 'Flash',
    computed: {
      ...mapState('flash', ['message']),
    },
    methods: {
      ...mapMutations('flash', ['next']),
      getColor (message) {
        switch (message.type) {
          case 'success':
            return 'success';
          case 'error':
            return 'error';
          case 'warning':
            return 'warning';
          default:
            return 'primary';
        }
      },
      isDismissible (message) {
        return message.dismissible === undefined ||
          message.dismissible
        ;
      },
    },
  };
</script>

<style scoped lang="sass">
  .list-item
    display: inline-block
    margin-right: 10px

  .list-enter-active, .list-leave-active
    transition: all 1s

  .list-enter, .list-leave-to /* .list-leave-active below version 2.1.8 */
    opacity: 0
    transform: translateY(30px)

</style>
